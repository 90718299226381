import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { logout, selectIsAuthorized } from '../../../redux/slices/authSlice';
import { routes } from '../../../constants/routes';

interface UserMenuProps {
  anchor: Element | null;
  close: () => void;
}

export default function UserMenu({ anchor, close }: UserMenuProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const user = useAppSelector((state) => state.auth.user);
  const signIn = () => {
    close();
    navigate(routes.login);
  };
  const signOut = () => {
    close();
    dispatch(logout());
    navigate(routes.root);
  };
  return (
    <Menu
      id="simple-menu"
      keepMounted
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={close}
    >
      {isAuthorized ? (
        <MenuList>
          <MenuItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText>{user?.name}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={signOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </MenuList>
      ) : (
        <MenuList>
          <MenuItem onClick={signIn}>
            <ListItemIcon>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText>Login</ListItemText>
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  );
}
