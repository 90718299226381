import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import GoogleSignIn from '../../atoms/GoogleSignIn/GoogleSignIn';
import FacebookSignIn from '../../atoms/FacebookSignIn/FacebookSignIn';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAccessToken } from '../../../redux/slices/authSlice';
import { IdentityProvider, User } from '../../../types/auth';
import AppPage from '../../templates/AppPage';
import { routes } from '../../../constants/routes';
import { endpoints } from '../../../constants/endpoints';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const user = useAppSelector((state) => state.auth.user);
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accessToken) {
      navigate(routes.root);
    }
  }, [accessToken]);

  useEffect(() => {
    const validateProviderToken = async (authUser: User) => {
      const endpoint =
        authUser.provider === IdentityProvider.Google
          ? endpoints.googleLogin
          : endpoints.facebookLogin;

      const data = {
        id_token: authUser.idToken,
        access_token: authUser.accessToken,
      };

      setLoading(true);

      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify(data),
        });
        if (response.status === 200) {
          const data = await response.json();
          dispatch(setAccessToken(data.authenticationToken));
        }
      } catch (e) {
        enqueueSnackbar('There was an error signing you in', {
          variant: 'error',
        });
      }

      setLoading(false);
    };
    if (user) {
      validateProviderToken(user);
    }
  }, [user, dispatch]);

  return (
    <AppPage maxWidth="lg">
      <Box sx={{ paddingBottom: { xs: 4, sm: 6 } }}>
        <Typography
          sx={{ typography: { xs: 'h4', sm: 'h2' } }}
          textAlign="center"
        >
          Welcome, future Invigorator!
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Stack spacing={2}>
            <GoogleSignIn />
            <FacebookSignIn />
          </Stack>
        )}
      </Box>
    </AppPage>
  );
};

export default LoginPage;
