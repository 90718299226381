import { endpoints } from '../../constants/endpoints';
import {
  CalendarIdeaResponse,
  CreateIdeaRequest,
  IdeaModel,
} from '../../types/idea';
import { baseApi } from './baseApi';

const ideasApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIdeaCount: builder.query<number, string>({
      query: (date) => endpoints.ideaCount(date),
    }),
    getIdeasForDate: builder.query<IdeaModel[], string>({
      query: (date) => endpoints.ideasForDate(date),
    }),
    getFirstIdea: builder.query<IdeaModel, void>({
      query: () => endpoints.firstIdea,
    }),
    getCalendarIdeas: builder.query<CalendarIdeaResponse[], string>({
      query: (date) => endpoints.calendarIdeas(date),
    }),
    submitIdea: builder.mutation<IdeaModel, CreateIdeaRequest>({
      query: (request) => ({
        url: endpoints.ideas,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Tags'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetIdeaCountQuery,
  useLazyGetIdeasForDateQuery,
  useLazyGetCalendarIdeasQuery,
  useGetFirstIdeaQuery,
  useSubmitIdeaMutation,
} = ideasApi;
