import Grid2 from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import AppPage from '../../templates/AppPage';
import IconUserStatBox from '../../molecules/IconUserStatBox';
import LightModeIcon from '@mui/icons-material/LightMode';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ProminentLinkButton from '../../atoms/ProminentLinkButton';
import { DateTime } from 'luxon';
import DailyTimer from '../../molecules/DailyTimer';
import { routes } from '../../../constants/routes';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetIdeaCountQuery } from '../../../redux/services/ideasApi';
import { useLazyGetUserStatsQuery } from '../../../redux/services/usersApi';
import CircularProgress from '@mui/material/CircularProgress';
import NamedUserStatBox from '../../molecules/NamedUserStatBox';
import ColoredUserStatBox from '../../molecules/ColoredUserStatBox';
import Divider from '@mui/material/Divider';
import ComboUserStatBox from '../../molecules/ComboUserStatBox';

const DashboardPage = () => {
  const user = useAppSelector((state) => state.auth.user);

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const sm = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md') && theme.breakpoints.up('sm')
  );
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [fetchIdeaCount, { data: ideaCount, isFetching: fetchingIdeaCount }] =
    useLazyGetIdeaCountQuery();
  const [fetchUserStats, { data: userStats, isFetching: fetchingUserStats }] =
    useLazyGetUserStatsQuery();

  useEffect(() => {
    const isoDateString = DateTime.now().toISO();
    const encodedDate = encodeURIComponent(isoDateString);
    fetchIdeaCount(encodedDate);
    fetchUserStats();
  }, []);

  const getSize = () => {
    if (lg) return 'large';
    else if (sm) return 'medium';
    else if (xs) return 'small';
    else return 'small';
  };

  const formatDate = (value?: string) => {
    return DateTime.fromISO(value ?? '').toLocaleString(DateTime.DATE_FULL);
  };

  const fetching = fetchingIdeaCount || fetchingUserStats;

  return (
    <AppPage maxWidth="xl">
      <Box
        sx={{
          paddingBottom: { xs: 4, sm: 8, md: 10 },
        }}
      >
        <Typography
          sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' } }}
          textAlign="center"
        >
          Hello, {user?.name ?? 'Invigorator'}!
        </Typography>
      </Box>
      {fetching ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid2 container spacing={2}>
            <Grid2 xs={6} sm={3}>
              <IconUserStatBox
                icon={
                  <LightModeIcon
                    sx={{ fontSize: { xs: 36, sm: 48, md: 64 } }}
                  />
                }
                value={userStats?.totalDays ?? 0}
                description="days since joining"
              />
            </Grid2>
            <Grid2 xs={6} sm={3}>
              <IconUserStatBox
                icon={
                  <LightbulbIcon
                    sx={{ fontSize: { xs: 36, sm: 48, md: 64 } }}
                  />
                }
                value={userStats?.totalIdeas ?? 0}
                description="ideas written"
              />
            </Grid2>
            <Grid2 xs={6} sm={3}>
              <IconUserStatBox
                icon={
                  <StarIcon sx={{ fontSize: { xs: 36, sm: 48, md: 64 } }} />
                }
                value={userStats?.activeStreak ?? 0}
                description="active streak"
              />
            </Grid2>
            <Grid2 xs={6} sm={3}>
              <IconUserStatBox
                icon={
                  <StarOutlineIcon
                    sx={{ fontSize: { xs: 36, sm: 48, md: 64 } }}
                  />
                }
                value={userStats?.longestStreak ?? 0}
                description="longest streak"
              />
            </Grid2>
          </Grid2>
          <Grid2
            container
            spacing={2}
            sx={{
              marginTop: { xs: 4, sm: 8, md: 10 },
            }}
          >
            <Grid2 xs={12} sm={6} md={4} mdOffset={2}>
              <ColoredUserStatBox
                name="Average daily"
                value={userStats?.averageIdeasDaily ?? 0}
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <ColoredUserStatBox
                name="Average weekly"
                value={userStats?.averageIdeasWeekly ?? 0}
              />
            </Grid2>
          </Grid2>
          <Grid2
            container
            columnSpacing={{ xs: 0, sm: 4, md: 6 }}
            rowSpacing={2}
            sx={{
              marginTop: { xs: 4, sm: 8, md: 10 },
            }}
          >
            <Grid2 xs={12} sm={6} md={4}>
              <NamedUserStatBox
                name="Idea days"
                value={userStats?.ideaDays ?? 0}
                description="Number of days with at least one idea"
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <NamedUserStatBox
                name="Invigorated days"
                value={userStats?.invigoratedDays ?? 0}
                description="Number of days with at least 10 ideas"
              />
            </Grid2>
            <Grid2 xs={12} sm={6} md={4}>
              <NamedUserStatBox
                name="On pause"
                value={userStats?.onPauseDays ?? 0}
                description="Number of days without any idea"
              />
            </Grid2>
          </Grid2>
          <Grid2
            container
            columnSpacing={{ xs: 0, sm: 4, md: 6 }}
            rowSpacing={2}
            sx={{
              marginTop: { xs: 4, sm: 8, md: 10 },
            }}
          >
            <Grid2 xs={12} sm={8} md={4} mdOffset={2}>
              <ComboUserStatBox
                name="Most inspired day"
                value={userStats?.mostInvigoratedDay?.ideaCount ?? 0}
                description={formatDate(userStats?.mostInvigoratedDay?.date)}
              />
            </Grid2>
            <Divider orientation="vertical" flexItem />
            <Grid2 xs={12} sm={8} md={4}>
              <ComboUserStatBox
                name="Most inspired week"
                value={userStats?.mostInvigoratedWeek?.ideaCount ?? 0}
                description={`${formatDate(
                  userStats?.mostInvigoratedWeek?.dateStart
                )} — ${formatDate(userStats?.mostInvigoratedWeek?.dateEnd)}`}
              />
            </Grid2>
          </Grid2>
          <Box
            sx={{
              py: { xs: 4, sm: 8, md: 10 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: { xs: 2, sm: 3, md: 4 },
            }}
          >
            <Typography
              sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' } }}
              textAlign="center"
            >
              {(ideaCount ?? 0) < 10
                ? 'Write at least 10 ideas today!'
                : 'Why stop there? Keep going!'}
            </Typography>
            <Stack
              direction="row"
              spacing={{ xs: 0, sm: 1, md: 2 }}
              alignItems="center"
            >
              {Array.from(Array(10), (e, i) => {
                if (i < (ideaCount ?? 0))
                  return (
                    <LightbulbIcon
                      key={i}
                      sx={{ fontSize: { xs: 32, sm: 48, md: 64 } }}
                    />
                  );
                else
                  return (
                    <LightbulbOutlinedIcon
                      key={i}
                      sx={{ fontSize: { xs: 32, sm: 48, md: 64 } }}
                    />
                  );
              })}
            </Stack>
            <DailyTimer size={getSize()} />
            <ProminentLinkButton
              route={routes.invigorate}
              text="Invigorate"
              size={getSize()}
              fullWidth={xs}
            />
          </Box>
        </>
      )}
    </AppPage>
  );
};

export default DashboardPage;
