import { endpoints } from '../../constants/endpoints';
import { UserStats } from '../../types/user';
import { baseApi } from './baseApi';

const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserStats: builder.query<UserStats, void>({
      query: () => endpoints.userStats,
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetUserStatsQuery } = usersApi;
