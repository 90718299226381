import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLazyGetDailyIdeasLeaderboardQuery } from '../../../redux/services/leaderboardsApi';
import DailyTimer from '../../molecules/DailyTimer';
import LeaderboardTable from '../../molecules/LeaderboardTable';

const DailyIdeasLeaderboard = () => {
  const [fetchEntries, { data, isFetching }] =
    useLazyGetDailyIdeasLeaderboardQuery();
  useEffect(() => {
    const isoDateString = DateTime.now().toISO();
    const encodedDate = encodeURIComponent(isoDateString);
    fetchEntries(encodedDate);
  }, []);
  return (
    <Box>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h4' } }} gutterBottom>
        Daily ideas
      </Typography>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <LeaderboardTable
          entries={data ?? []}
          emptyText="No ideas today yet, be the first one!"
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        <Typography sx={{ typography: { xs: 'subtitle1', sm: 'h6' } }}>
          Time remaining
        </Typography>
        <DailyTimer size="small" />
      </Box>
    </Box>
  );
};

export default DailyIdeasLeaderboard;
