import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import Calendar from '../../organisms/Calendar';
import AppPage from '../../templates/AppPage';
import {
  useGetFirstIdeaQuery,
  useLazyGetCalendarIdeasQuery,
} from '../../../redux/services/ideasApi';
import { CalendarIdeaResponse } from '../../../types/idea';
import LinearProgress from '@mui/material/LinearProgress';

const CalendarPage = () => {
  const [currentMonth, setCurrentMonth] = useState(
    DateTime.now().startOf('month')
  );

  const { data: firstIdea } = useGetFirstIdeaQuery();
  const [fetchIdeas, { data: ideas, isFetching }] =
    useLazyGetCalendarIdeasQuery();

  useEffect(() => {
    const encodedDate = encodeURIComponent(currentMonth.toISO());
    fetchIdeas(encodedDate);
  }, [currentMonth]);

  const mapIdeas = () => {
    return ideas
      ? ideas.map((d: CalendarIdeaResponse) => ({
          date: DateTime.fromISO(d.date),
          count: d.count,
        }))
      : [];
  };

  return (
    <AppPage maxWidth="sm">
      <Box sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 5fr 1fr',
            alignItems: 'center',
          }}
        >
          <Box>
            <IconButton
              size="large"
              onClick={() => setCurrentMonth(currentMonth.minus({ month: 1 }))}
              disabled={
                firstIdea &&
                currentMonth.hasSame(
                  DateTime.fromISO(firstIdea.createdAt),
                  'month'
                )
              }
            >
              <ChevronLeftIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            variant="h4"
            sx={{ typography: { xs: 'h6', sm: 'h4' }, textAlign: 'center' }}
          >
            {currentMonth.toFormat('LLLL yyyy')}
          </Typography>
          <Box sx={{ textAlign: 'right' }}>
            <IconButton
              size="large"
              onClick={() => setCurrentMonth(currentMonth.plus({ month: 1 }))}
              disabled={currentMonth.hasSame(DateTime.now(), 'month')}
            >
              <ChevronRightIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        {isFetching && (
          <Box sx={{ marginTop: -0.5 }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
      <Calendar month={currentMonth} ideas={mapIdeas()} />
    </AppPage>
  );
};

export default CalendarPage;
