import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface NamedUserStatBoxProps {
  name: string;
  value: number;
  description: string;
}

export default function NamedUserStatBox({
  name,
  value,
  description,
}: NamedUserStatBoxProps) {
  return (
    <Box>
      <Divider sx={{ marginBottom: { xs: 1, sm: 2 } }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' } }}>{name}</Typography>
        <Typography sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' } }}>
          {value}
        </Typography>
      </Box>
      <Typography
        sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1', md: 'h6' } }}
      >
        {description}
      </Typography>
      <Divider sx={{ marginTop: { xs: 1, sm: 2 } }} />
    </Box>
  );
}
