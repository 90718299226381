import Container from '@mui/material/Container';
import { Breakpoint } from '@mui/material/styles';
import { getFooterSizes } from '../../../constants/dimensions';

interface AppPageProps {
  maxWidth: Breakpoint;
  children: React.ReactNode;
}

const AppPage = ({ maxWidth, children }: AppPageProps) => {
  const styles = {
    paddingTop: { xs: 2, md: 4 },
    paddingBottom: { xs: 2, md: 4 },
  };
  return (
    <Container maxWidth={maxWidth} sx={styles}>
      {children}
    </Container>
  );
};

export default AppPage;
