export enum IdentityProvider {
  Google = 'google',
  Facebook = 'facebook'
}

export type User = {
  provider: IdentityProvider;
  userId: string;
  accessToken: string;
  idToken?: string;
  name: string;
  email: string;
  imageUrl: string;
};
