import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Sizes } from '../../../constants/dimensions';

export interface TimerComponentProps {
  value: number;
  label: string;
  size: Sizes;
}

export default function TimerComponent({
  value,
  label,
  size,
}: TimerComponentProps) {
  const getValueVariant = () => {
    switch (size) {
      case 'small': return 'h5';
      case 'medium': return 'h4';
      case 'large': return 'h3';
    }
  };
  const getLabelVariant = () => {
    switch (size) {
      case 'small': return 'body2';
      case 'medium': return 'body1';
      case 'large': return 'h6';
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 1,
        marginRight: 1,
      }}
    >
      <Typography variant={getValueVariant()}>{value}</Typography>
      <Typography variant={getLabelVariant()} color="primary">
        {label}
      </Typography>
    </Box>
  );
}
