import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { IdeaModel } from '../../../types/idea';
import { DateTime } from 'luxon';
import { Divider } from '@mui/material';

interface IdeaProps {
  index: number;
  idea: IdeaModel;
}

const Idea = ({ index, idea }: IdeaProps) => {
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const createdAt = DateTime.fromISO(idea.createdAt, { setZone: true });
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          padding: { xs: 0.5, sm: 1 },
          marginRight: { xs: 1, sm: 2 },
        }}
      >
        <Typography sx={{ typography: { xs: 'h5', sm: 'h4' } }}>
          {index + 1}
        </Typography>
      </Box>
      <Box sx={{ my: { xs: 1, sm: 2 } }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: { xs: 1, sm: 2 },
          }}
        >
          <AccessTimeIcon
            sx={{ fontSize: { xs: 16, sm: 20 }, marginRight: 0.5 }}
          />
          <Typography variant="body2">
            {createdAt.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}
          </Typography>
        </Box>
        {idea.topic && (
          <Box
            sx={{
              marginBottom: { xs: 1, sm: 2 },
            }}
          >
            <Typography sx={{ typography: { xs: 'body2', sm: 'body1' } }}>
              {idea.topic}
            </Typography>
            <Divider />
          </Box>
        )}
        <Typography sx={{ typography: { xs: 'subtitle1', sm: 'h6' } }}>
          {idea.description}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginTop: { xs: 1, sm: 2 },
          }}
        >
          {idea.tags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              variant="filled"
              size={xs ? 'small' : 'medium'}
              sx={{ marginRight: 1, my: 0.5 }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Idea;
