import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PreferenceState {
  useDarkTheme: boolean;
}

const initialState: PreferenceState = {
  useDarkTheme: true,
};

export const preferenceSlice = createSlice({
  name: 'preference',
  initialState: initialState,
  reducers: {
    setDarkTheme: (state, action: PayloadAction<boolean>) => {
      state.useDarkTheme = action.payload;
    },
    toggleDarkTheme: (state, action: PayloadAction) => {
      state.useDarkTheme = !state.useDarkTheme;
    },
  },
});

export const { setDarkTheme, toggleDarkTheme } = preferenceSlice.actions;

export default preferenceSlice.reducer;
