import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { HeaderMenuButtonProps } from '../../atoms/HeaderMenuButton';

interface NavigationDrawerProps {
  menuItems: HeaderMenuButtonProps[];
  open: boolean;
  close: () => void;
}

export default function NavigationDrawer({
  menuItems,
  open,
  close,
}: NavigationDrawerProps) {
  const navigate = useNavigate();
  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={close}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
      }}
    >
      <Box onClick={close} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2 }}>
          INVIGORIAM
        </Typography>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.route)}
                sx={{ textAlign: 'center' }}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
