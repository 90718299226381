import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import HeaderLogoImage from '../../atoms/HeaderLogoImage';

export default function HeaderLogo() {
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  return (
    <Button
      component={Link}
      to={routes.root}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        color: 'text.primary',
        padding: 0,
      }}
    >
      <HeaderLogoImage size={md ? 'medium' : 'large'} />
      <Typography
        variant="h5"
        sx={{
          typography: { md: 'h5', lg: 'h4' },
          textTransform: 'uppercase',
          letterSpacing: { sm: 4, lg: 5 },
        }}
      >
        Invigoriam
      </Typography>
    </Button>
  );
}
