import { endpoints } from '../../constants/endpoints';
import { TagModel } from '../../types/tag';
import { baseApi } from './baseApi';

const tagsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listTags: builder.query<TagModel[], void>({
      query: () => endpoints.tags,
      providesTags: ['Tags'],
    }),
  }),
  overrideExisting: false,
});

export const { useListTagsQuery } = tagsApi;
