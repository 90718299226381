import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useLazyGetActiveStreaksLeaderboardQuery } from '../../../redux/services/leaderboardsApi';
import LeaderboardTable from '../../molecules/LeaderboardTable';

const ActiveStreaksLeaderboard = () => {
  const [fetchEntries, { data, isFetching }] =
    useLazyGetActiveStreaksLeaderboardQuery();
  useEffect(() => {
    fetchEntries();
  }, []);
  return (
    <Box>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h4' } }} gutterBottom>
        Active streaks
      </Typography>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <LeaderboardTable
          entries={data ?? []}
          emptyText="No active streaks at this time."
        />
      )}
    </Box>
  );
};

export default ActiveStreaksLeaderboard;
