import { endpoints } from '../../constants/endpoints';
import { LeaderboardEntry } from '../../types/leaderboard';
import { baseApi } from './baseApi';

const leaderboardsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDailyIdeasLeaderboard: builder.query<LeaderboardEntry[], string>({
      query: (date) => endpoints.dailyIdeasLeaderboard(date),
    }),
    getTotalIdeasLeaderboard: builder.query<LeaderboardEntry[], void>({
      query: () => endpoints.totalIdeasLeaderboard,
    }),
    getActiveStreaksLeaderboard: builder.query<LeaderboardEntry[], void>({
      query: () => endpoints.activeStreaksLeaderboard,
    }),
    getLongestStreaksLeaderboard: builder.query<LeaderboardEntry[], void>({
      query: () => endpoints.longestStreaksLeaderboard,
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetDailyIdeasLeaderboardQuery,
  useLazyGetTotalIdeasLeaderboardQuery,
  useLazyGetActiveStreaksLeaderboardQuery,
  useLazyGetLongestStreaksLeaderboardQuery,
} = leaderboardsApi;
