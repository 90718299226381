import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Sizes } from '../../../constants/dimensions';
import TimerComponent from '../../atoms/TimerComponent';

interface DailyTimerProps {
  size: Sizes;
}

const DailyTimer = ({ size }: DailyTimerProps) => {
  const calculateTimeLeft = () => {
    const now = DateTime.now();
    const tomorrow = DateTime.now().plus({ days: 1 }).startOf('day');
    const difference = tomorrow.diff(now, ['hours', 'minutes', 'seconds']);
    return difference;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    return () => clearTimeout(timer);
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <TimerComponent size={size} value={timeLeft.hours} label="hours" />
      <TimerComponent size={size} value={timeLeft.minutes} label="minutes" />
      <TimerComponent size={size} value={Math.floor(timeLeft.seconds)} label="seconds" />
    </Box>
  );
};

export default DailyTimer;
