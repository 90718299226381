import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useLazyGetLongestStreaksLeaderboardQuery } from '../../../redux/services/leaderboardsApi';
import LeaderboardTable from '../../molecules/LeaderboardTable';

const LongestStreaksLeaderboard = () => {
  const [fetchEntries, { data, isFetching }] =
    useLazyGetLongestStreaksLeaderboardQuery();
  useEffect(() => {
    fetchEntries();
  }, []);
  return (
    <Box>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h4' } }} gutterBottom>
        Longest streaks
      </Typography>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <LeaderboardTable
          entries={data ?? []}
          emptyText="No streaks have been recoreded yet."
        />
      )}
    </Box>
  );
};

export default LongestStreaksLeaderboard;
