import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import AppPage from '../../templates/AppPage';
import { useSubmitFeedbackMutation } from '../../../redux/services/baseApi';

const FeedbackPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [submitFeedback, { isLoading, isSuccess, isError }] =
    useSubmitFeedbackMutation();

  useEffect(() => {
    if (isSuccess) {
      setText('');
      enqueueSnackbar('Feedback submitted', { variant: 'success' });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(`An error occured`, { variant: 'error' });
    }
  }, [isError]);

  const [text, setText] = useState<string>('');
  const onChange = (event: any) => setText(event.target.value);

  const submit = () => {
    submitFeedback({ text });
  };

  return (
    <AppPage maxWidth="sm">
      <Typography
        sx={{
          typography: { xs: 'h6', sm: 'h4' },
          marginBottom: { xs: 2, sm: 4 },
        }}
      >
        Tell us what you like and what could be improved!
      </Typography>
      <Box sx={{ py: { xs: 2, sm: 4 } }}>
        <TextField
          id="standard-multiline-static"
          label="Enter your feedback"
          multiline
          fullWidth
          rows={4}
          value={text}
          onChange={onChange}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Box>
    </AppPage>
  );
};

export default FeedbackPage;
