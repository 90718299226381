import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

interface ComboUserStatBoxProps {
  name: string;
  value: number;
  description: string;
}

export default function ComboUserStatBox({
  name,
  value,
  description,
}: ComboUserStatBoxProps) {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' } }}>
          {name}
        </Typography>
        <LightbulbIcon
          sx={{ fontSize: { xs: 36, sm: 48, md: 64 }, marginLeft: 'auto' }}
        />
        <Typography sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' } }}>
          {value}
        </Typography>
      </Box>
      <Typography
        sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1', md: 'h6' } }}
      >
        {description}
      </Typography>
    </Box>
  );
}
