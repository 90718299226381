import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import {
  CalendarDay,
  CalendarIdea,
  CalendarMonth,
} from '../../../types/calendar';
import CalendarDate from '../../atoms/CalendarDate';
import CalendarRow from '../../molecules/CalendarRow';

interface CalendarProps {
  month: DateTime;
  ideas: CalendarIdea[];
}

const Calendar = ({ month, ideas }: CalendarProps) => {
  const navigate = useNavigate();
  const buildCalendar = (
    startOfMonth: DateTime,
    ideas: CalendarIdea[]
  ): CalendarMonth => {
    let current = startOfMonth.startOf('week');
    const calendarMonth: CalendarMonth = {
      weeks: [],
    };
    for (let i = 0; i < 6; i++) {
      calendarMonth.weeks[i] = {
        number: i + 1,
        days: [],
      };
      for (let j = 0; j < 7; j++) {
        const ideasForDate = ideas.find((c) => c.date.hasSame(current, 'day'));
        const isDisabled = current.month !== startOfMonth.month;
        const isActive = !isDisabled && !!ideasForDate;

        calendarMonth.weeks[i].days[j] = {
          date: current,
          active: isActive,
          completed: isActive && !!ideasForDate && ideasForDate.count >= 10,
          disabled: isDisabled,
        };

        current = current.plus({ days: 1 });
      }
    }
    return calendarMonth;
  };
  const calendar = buildCalendar(month, ideas);
  const handleClick = (day: CalendarDay) => {
    if (day.active) {
      navigate(
        `${routes.ideas}?${createSearchParams({
          date: day.date.toISO(),
        })}`
      );
    }
  };
  return (
    <Box>
      <CalendarRow>
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((dayOfWeek) => (
          <Typography
            key={dayOfWeek}
            variant="body1"
            component="span"
            color="text.primary"
            sx={{ width: { xs: 42, sm: 54 }, textAlign: 'center' }}
          >
            {dayOfWeek}
          </Typography>
        ))}
      </CalendarRow>
      {calendar.weeks.map((week) => (
        <CalendarRow key={week.number}>
          {week.days.map((day) => (
            <CalendarDate
              key={day.date.toISO()}
              day={day}
              onClick={() => handleClick(day)}
            />
          ))}
        </CalendarRow>
      ))}
    </Box>
  );
};

export default Calendar;
