import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface IconUserStatBoxProps {
  icon: React.ReactNode;
  value: number;
  description: string;
}

export default function IconUserStatBox({
  icon,
  value,
  description,
}: IconUserStatBoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        <Typography sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' } }}>
          {value}
        </Typography>
      </Box>
      <Typography
        sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1', md: 'h6' } }}
        textAlign="center"
      >
        {description}
      </Typography>
    </Box>
  );
}
