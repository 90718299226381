import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Sizes } from '../../../constants/dimensions';
import { routes } from '../../../constants/routes';

interface HeaderLogoImageProps {
  clickable?: boolean;
  size?: Sizes;
}

const HeaderLogoImage = memo(function Image({
  clickable,
  size,
}: HeaderLogoImageProps) {
  const imageSize = size === 'large' ? 64 : size === 'medium' ? 52 : 48;
  const image = (
    <Box
      component="img"
      sx={{
        height: imageSize,
        width: imageSize,
      }}
      alt="Invigoriam logo icon"
      src="/images/invigoriam-logo-A7-icon.png"
    />
  );

  if (clickable) {
    return (
      <Button component={Link} to={routes.root}>
        {image}
      </Button>
    );
  }

  return image;
});

export default HeaderLogoImage;
