import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

interface ColoredUserStatBoxProps {
  name: string;
  value: number;
}

export default function ColoredUserStatBox({
  name,
  value,
}: ColoredUserStatBoxProps) {
  const round = (
    value: number,
    minimumFractionDigits: number,
    maximumFractionDigits: number
  ) => {
    const formattedValue = value.toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return Number(formattedValue);
  };
  return (
    <Card variant="outlined" sx={{ bgcolor: 'secondary.main' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: { xs: 2, sm: 4 },
          padding: { xs: 1, sm: 2 },
        }}
      >
        <Typography
          sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' }, color: 'black' }}
        >
          {name}
        </Typography>
        <Typography
          sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' }, color: 'black' }}
        >
          {round(value, 2, 2)}
        </Typography>
      </Box>
    </Card>
  );
}
