import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { LeaderboardEntry } from '../../../types/leaderboard';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface LeaderboardTableProps {
  entries: LeaderboardEntry[];
  emptyText: string;
}

const LeaderboardTable = ({ entries, emptyText }: LeaderboardTableProps) => {
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  if (entries.length === 0) {
    return <Typography variant="body1">{emptyText}</Typography>;
  }
  return (
    <TableContainer component={Paper}>
      <Table size={xs ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell align="right">Rank</TableCell>
            <TableCell>Username</TableCell>
            <TableCell align="right">Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => (
            <TableRow
              key={entry.rank}
              sx={{
                backgroundColor: entry.isCurrentUser ? '#ac4db6' : 'inherit',
              }}
            >
              <TableCell align="right">{entry.rank}</TableCell>
              <TableCell>{entry.username}</TableCell>
              <TableCell align="right">{entry.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderboardTable;
