import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { forceLogout } from '../slices/authSlice';

export const apiErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!', action);
      if (action.payload.status === 401) {
        api.dispatch(forceLogout());
      }
    }
    return next(action);
  };
