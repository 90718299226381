import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { CalendarDay } from '../../../types/calendar';

interface CalendarDateProps {
  day: CalendarDay;
  onClick: () => void;
}

export default function CalendarDate({ day, onClick }: CalendarDateProps) {
  const isToday = day.date.hasSame(DateTime.now(), 'day');
  return (
    <Typography
      variant="h6"
      component="span"
      sx={{
        fontSize: { xs: 18, sm: 20 },
        width: { xs: 42, sm: 56 },
        textAlign: 'center',
        opacity: day.disabled ? 0.25 : 1,
        color: day.completed ? '#000000' : 'text.primary',
        backgroundColor: day.completed ? 'primary.main' : 'inherit',
        borderWidth: day.active || isToday ? '1px' : 'initial',
        borderStyle: day.active || isToday ? 'solid' : 'none',
        borderColor: day.active
          ? 'primary.main'
          : isToday
          ? 'primary'
          : 'transparent',
        py: { xs: 0.5, sm: 1 },
        px: { xs: 1, sm: 2 },
        ':hover': {
          color: '#363842',
          backgroundColor: 'secondary.main',
          borderColor: 'secondary.main',
          cursor: day.active ? 'pointer' : 'default',
        },
      }}
      onClick={onClick}
    >
      {day.date.day}
    </Typography>
  );
}
