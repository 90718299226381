import Grid from '@mui/material/Grid';
import ActiveStreaksLeaderboard from '../../organisms/ActiveStreaksLeaderboard';
import DailyIdeasLeaderboard from '../../organisms/DailyIdeasLeaderboard';
import LongestStreaksLeaderboard from '../../organisms/LongestStreaksLeaderboard';
import TotalIdeasLeaderboard from '../../organisms/TotalIdeasLeaderboard';
import AppPage from '../../templates/AppPage';

const LeaderboardsPage = () => {
  return (
    <AppPage maxWidth="lg">
      <Grid container spacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} md={6}>
          <DailyIdeasLeaderboard />
        </Grid>
        <Grid item xs={12} md={6}>
          <ActiveStreaksLeaderboard />
        </Grid>
        <Grid item xs={12} md={6}>
          <TotalIdeasLeaderboard />
        </Grid>
        <Grid item xs={12} md={6}>
          <LongestStreaksLeaderboard />
        </Grid>
      </Grid>
    </AppPage>
  );
};

export default LeaderboardsPage;
