import Box from '@mui/material/Box';

interface CalendarRowProps {
  children: React.ReactNode;
}

export default function CalendarRow({ children }: CalendarRowProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        justifyItems: 'center',
        alignItems: 'center',
        marginBottom: { xs: 1, sm: 2 },
      }}
    >
      {children}
    </Box>
  );
}
