import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AndroidIcon from '@mui/icons-material/Android';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Typography from '@mui/material/Typography';
import { getIconSizes, getFooterSizes } from '../../../constants/dimensions';
import { Link } from 'react-router-dom';
import { routes } from '../../../constants/routes';

const Footer = () => {
  const label = (text: string) => (
    <Typography
      sx={{
        typography: { xs: 'subtitle2', sm: 'subtitle1', md: 'h6' },
        marginRight: { xs: 0.5, sm: 1 },
        textTransform: 'uppercase',
      }}
    >
      {text}
    </Typography>
  );
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'transparent',
        width: '100%',
        minHeight: getFooterSizes(),
        padding: { xs: 2, sm: 3, md: 4, lg: 6 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Button
          component={Link}
          to={routes.privacy}
          sx={{ typography: { xs: 'body2', sm: 'body1' } }}
        >
          Privacy
        </Button>
        <Box sx={{ marginLeft: 'auto' }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {label('Get the app')}
        </Box>
        <IconButton
          href="https://play.google.com/store/apps/details?id=com.invigoriam.android.apps.ideas"
          target="_blank"
          rel="noreferrer"
        >
          <AndroidIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {label('Follow us')}
        </Box>
        <IconButton
          href="https://facebook.com/invigoriam"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
        <IconButton
          href="https://instagram.com/invigoriam"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
        <IconButton
          href="https://twitter.com/invigoriam"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
        <IconButton
          href="https://www.linkedin.com/company/invigoriam"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
