import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export interface HeaderMenuButtonProps {
  text: string;
  route: string;
}

export default function HeaderMenuButton({
  text,
  route,
}: HeaderMenuButtonProps) {
  return (
    <Button
      component={Link}
      to={route}
      size="large"
      sx={{ fontSize: { md: 16, lg: 18 } }}
    >
      {text}
    </Button>
  );
}
