import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import AppPage from '../../templates/AppPage';
import Link from '@mui/material/Link';

interface BulletListProps {
  children: React.ReactNode;
  marginBottom?: boolean;
}

const BulletList = ({ marginBottom, children }: BulletListProps) => {
  return (
    <List
      sx={{
        listStyleType: 'disc',
        pl: 4,
        '& .MuiListItem-root': {
          display: 'list-item',
        },
        typography: { xs: 'body2', sm: 'body1' },
        marginBottom: marginBottom ? { xs: 3, sm: 4 } : 'none',
      }}
    >
      {children}
    </List>
  );
};

const PrivacyPage = () => {
  return (
    <AppPage maxWidth="md">
      <Typography
        sx={{
          typography: { xs: 'h4', sm: 'h3' },
          marginBottom: { xs: 3, sm: 4 },
        }}
        textAlign="center"
      >
        Privacy policy
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'subtitle2', sm: 'subtitle1' },
          marginBottom: { xs: 1, sm: 2 },
        }}
      >
        This privacy policy will explain how we use the personal data we collect
        from you when you use our systems.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        Topics:
      </Typography>
      <BulletList marginBottom>
        <ListItem disableGutters>What data do we collect?</ListItem>
        <ListItem disableGutters>How do we collect your data?</ListItem>
        <ListItem disableGutters>How will we use your data?</ListItem>
        <ListItem disableGutters>How do we store your data?</ListItem>
        <ListItem disableGutters>
          What are your data protection rights?
        </ListItem>
        <ListItem disableGutters>What are cookies?</ListItem>
        <ListItem disableGutters>How do we use cookies?</ListItem>
        <ListItem disableGutters>What types of cookies do we use?</ListItem>
        <ListItem disableGutters>How to manage your cookies</ListItem>
        <ListItem disableGutters>Privacy policies of other websites</ListItem>
        <ListItem disableGutters>Changes to our privacy policy</ListItem>
        <ListItem disableGutters>How to contact us</ListItem>
        <ListItem disableGutters>
          How to contact the appropriate authorities
        </ListItem>
      </BulletList>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        What data do we collect?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        We collect the following data:
      </Typography>
      <BulletList marginBottom>
        <ListItem disableGutters>
          Personal identification information (your user identifier at the
          identity provider, name, email address).
        </ListItem>
      </BulletList>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How do we collect your data?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        You directly provide us with most of the data we collect. We collect
        data and process data when you:
      </Typography>
      <BulletList marginBottom>
        <ListItem disableGutters>Sign up with Invigoriam.</ListItem>
        <ListItem disableGutters>Provide feedback to us.</ListItem>
        <ListItem disableGutters>Submit ideas.</ListItem>
      </BulletList>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How will we use your data?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        We collect your data so that we can:
      </Typography>
      <BulletList>
        <ListItem disableGutters>Manage your account.</ListItem>
        <ListItem disableGutters>
          Keep track of your ideas and provide you with statistics and
          achievements.
        </ListItem>
      </BulletList>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        We do not share your data with any third party.
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How do we store your data?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        We securely store your data at a data center within European Union.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        We will keep your data until you request the deletion of your account.
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        What are your data protection rights?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 1, sm: 2 },
        }}
      >
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        <strong>The right to access</strong> - You have the right to request us
        for copies of your personal data. We may charge you a small fee for this
        service.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        <strong>The right to rectification</strong> - You have the right to
        request that we correct any information you believe is inaccurate. You
        also have the right to request us to complete the information you
        believe is incomplete.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        <strong>The right to erasure</strong> - You have the right to request
        that we erase your personal data, under certain conditions.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        <strong>The right to restrict processing </strong> - You have the right
        to request that we restrict the processing of your personal data, under
        certain conditions.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        <strong>The right to object to processing</strong> - You have the right
        to object to our processing of your personal data, under certain
        conditions.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        <strong>The right to data portability</strong> - You have the right to
        request that we transfer the data that we have collected to another
        organization, or directly to you, under certain conditions. If you make
        a request, we have one month to respond to you. If you would like to
        exercise any of these rights, please contact us at our email:{' '}
        <Link
          href="mailto:info@invigoriam.com"
          target="_blank"
          rel="noreferrer"
        >
          info@invigoriam.com
        </Link>
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        Cookies
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behavior information. When you
        visit our websites, we may collect information from you automatically
        through cookies or similar technology.
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        For further information, visit{' '}
        <Link
          href="https://allaboutcookies.org"
          target="_blank"
          rel="noreferrer"
        >
          allaboutcookies.org
        </Link>
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How do we use cookies?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        We use cookies in a range of ways to improve your experience on our
        website, including:
      </Typography>
      <BulletList marginBottom>
        <ListItem disableGutters>Keeping you signed in.</ListItem>
      </BulletList>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        What types of cookies do we use?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
        }}
      >
        There are a number of different types of cookies, however, our website
        uses:
      </Typography>
      <BulletList marginBottom>
        <ListItem disableGutters>
          Functionality - we use these cookies so that we recognize you on our
          website and remember your previously selected preferences. These could
          include what language you prefer and location you are in. A mix of
          first-party and third-party cookies are used.
        </ListItem>
      </BulletList>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How to manage cookies
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        You can set your browser not to accept cookies, and the above website
        tells you how to remove cookies from your browser. However, in a few
        cases, some of our website features may not function as a result.
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        Privacy policies of other websites
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        Our systems might contain links to other websites. Our privacy policy
        applies only to our systems, so if you click on a link to another
        website, you should read their privacy policy.
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        Changes to our privacy policy
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        We keep our privacy policy under regular review and place any updates on
        this web page. This privacy policy was last updated on 30 September
        2022.
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How to contact us
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        If you have any questions about our privacy policy, the data we hold on
        you, or you would like to exercise one of your data protection rights,
        please do not hesitate to contact us. Email us at:{' '}
        <Link
          href="mailto:info@invigoriam.com"
          target="_blank"
          rel="noreferrer"
        >
          info@invigoriam.com
        </Link>
      </Typography>

      <Typography
        sx={{
          typography: { xs: 'h5', sm: 'h4' },
          marginBottom: { xs: 1, sm: 2 },
        }}
        textAlign="center"
      >
        How to contact the appropriate authority
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'body2', sm: 'body1' },
          marginBottom: { xs: 3, sm: 4 },
        }}
      >
        Should you wish to report a complaint or if you feel that we have not
        addressed your concern in a satisfactory manner, you may contact the
        government institutions.
      </Typography>
    </AppPage>
  );
};

export default PrivacyPage;
