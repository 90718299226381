import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { TopicModel } from '../../../types/topic';

interface TopicOfTheDayProps {
  topic: TopicModel;
}

const TopicOfTheDay = ({ topic }: TopicOfTheDayProps) => {
  return (
    <Card
      sx={{
        marginBottom: { xs: 2, sm: 4 },
      }}
    >
      <CardContent
        sx={{
          padding: { xs: 1, sm: 2 },
          '&:last-child': { paddingBottom: { xs: 1, sm: 2 } },
        }}
      >
        <Typography
          sx={{
            typography: { xs: 'body2', sm: 'body1' },
          }}
          color="text.secondary"
          gutterBottom
        >
          Topic of the Day
        </Typography>
        <Typography
          sx={{
            typography: { xs: 'subtitle1', sm: 'h5' },
          }}
          component="div"
          gutterBottom
        >
          {topic.name}
        </Typography>
        <Typography
          sx={{
            typography: { xs: 'body2', sm: 'body1' },
          }}
          color="text.secondary"
          textAlign="right"
        >
          Added by {topic.createdBy}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TopicOfTheDay;
