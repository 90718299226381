export const routes = {
  root: '/',
  login: '/login',
  invigorate: '/invigorate',
  calendar: '/calendar',
  leaderboards: '/leaderboards',
  ideas: '/ideas',
  feedback: '/feedback',
  privacy: '/privacy',
};
