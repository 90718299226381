import { useAppDispatch } from '../../../redux/hooks';
import { IdentityProvider } from '../../../types/auth';
import { login } from '../../../redux/slices/authSlice';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

const GoogleSignIn = () => {
  const dispatch = useAppDispatch();

  const handleCallback = (response: google.accounts.id.CredentialResponse) => {
    const jwt = jwt_decode(response.credential) as any;
    dispatch(
      login({
        provider: IdentityProvider.Google,
        userId: jwt.sub,
        accessToken: response.credential,
        idToken: response.credential,
        name: jwt.name,
        email: jwt.email,
        imageUrl: jwt.picture,
      })
    );
  };

  useEffect(() => {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
      callback: handleCallback,
    });
    google.accounts.id.renderButton(
      document.getElementById('google-sign-in')!,
      { theme: 'outline', size: 'large', type: 'standard' }
    );
    google.accounts.id.prompt();
  }, []);

  return <div id="google-sign-in"></div>;
};

export default GoogleSignIn;
