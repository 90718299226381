import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import IdeaCounter from '../../atoms/IdeaCounter';
import IdeaPager from '../../molecules/IdeaPager';
import { DateTime } from 'luxon';
import AppPage from '../../templates/AppPage';
import {
  useLazyGetIdeaCountQuery,
  useSubmitIdeaMutation,
} from '../../../redux/services/ideasApi';
import { useGetTopicOfTheDayQuery } from '../../../redux/services/topicsApi';
import IdeaTags from '../../molecules/IdeaTags';
import TopicOfTheDay from '../../molecules/TopicOfTheDay';
import TopicSelection from '../../molecules/TopicSelection';
import { TopicModel } from '../../../types/topic';

const InvigoratePage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { data: topicOfTheDay } = useGetTopicOfTheDayQuery(
    DateTime.now().toISODate()
  );
  const [fetchIdeaCount, { data: ideaCount }] = useLazyGetIdeaCountQuery();
  const [submitIdea, { isLoading, isSuccess, isError }] =
    useSubmitIdeaMutation();

  useEffect(() => {
    const isoDateString = DateTime.now().toISO();
    const encodedDate = encodeURIComponent(isoDateString);
    fetchIdeaCount(encodedDate);
  }, []);

  useEffect(() => {
    setCount(ideaCount ?? 0);
  }, [ideaCount]);

  useEffect(() => {
    if (isSuccess) {
      setText('');
      setCount(count + 1);
      enqueueSnackbar('Idea submitted', { variant: 'success' });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('An error occured', { variant: 'error' });
    }
  }, [isError]);

  const [text, setText] = useState('');
  const [count, setCount] = useState(0);
  const [tags, setTags] = useState<string[]>([]);
  const [topic, setTopic] = useState<TopicModel | undefined>(undefined);

  const onTextChange = (event: any) => setText(event.target.value);

  const addTag = (tag: string) => setTags([...tags, tag]);
  const removeTag = (tag: string) => setTags(tags.filter((t) => t !== tag));

  const submit = () => {
    submitIdea({
      description: text,
      source: 1,
      createdAt: DateTime.now().toISO(),
      tags: tags,
      topicId: topic?.id,
    });
  };

  const useTopics = topicOfTheDay !== undefined;

  return (
    <AppPage maxWidth="sm">
      {topicOfTheDay && <TopicOfTheDay topic={topicOfTheDay} />}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <IdeaCounter value={count} threshold={10} />
        <Tooltip
          title="Write down at least 10 ideas every day, unlock your idea potential!"
          placement="left"
        >
          <LightbulbOutlinedIcon
            sx={{
              fontSize: { xs: 24, sm: 36 },
              marginLeft: 'auto',
              color: 'primary.main',
            }}
          />
        </Tooltip>
      </Box>
      <Divider />
      {useTopics && (
        <TopicSelection
          selectedTopic={topic}
          topicOfTheDay={topicOfTheDay}
          onTopicChanged={setTopic}
        />
      )}
      <Box sx={{ py: { xs: 2, sm: 4 } }}>
        <TextField
          id="standard-multiline-static"
          label="Describe your idea here"
          multiline
          fullWidth
          rows={5}
          value={text}
          onChange={onTextChange}
        />
        <IdeaTags tags={tags} onTagAdded={addTag} onTagRemoved={removeTag} />
      </Box>
      <Divider />
      <Box sx={{ marginTop: { xs: 1, sm: 2 }, marginBottom: { xs: 3, sm: 4 } }}>
        <IdeaPager current={count + 1} total={10} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={submit}
          disabled={isLoading}
          fullWidth={xs}
        >
          Submit
        </Button>
      </Box>
    </AppPage>
  );
};

export default InvigoratePage;
