import { endpoints } from '../../constants/endpoints';
import { TopicModel } from '../../types/topic';
import { baseApi } from './baseApi';

const topicsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopicOfTheDay: builder.query<TopicModel, string>({
      query: (date) => endpoints.topicOfTheDay(date),
    }),
  }),
  overrideExisting: false,
});

export const { useGetTopicOfTheDayQuery } = topicsApi;
