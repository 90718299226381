import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TopicModel } from '../../../types/topic';

interface TopicSelectionProps {
  selectedTopic?: TopicModel;
  topicOfTheDay?: TopicModel;
  onTopicChanged: (topic?: TopicModel) => void;
}

const TopicSelection = ({
  selectedTopic,
  topicOfTheDay,
  onTopicChanged,
}: TopicSelectionProps) => {
  return (
    <Paper
      variant="outlined"
      sx={{ mt: { xs: 2, sm: 4 }, padding: { xs: 1, sm: 2 } }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography
          sx={{
            typography: { xs: 'body2', sm: 'body1' },
          }}
        >
          Topic:
        </Typography>
        {selectedTopic ? (
          <Typography
            sx={{
              typography: { xs: 'body2', sm: 'body1' },
            }}
          >
            {selectedTopic.name}
          </Typography>
        ) : (
          <Button
            variant="outlined"
            size="small"
            onClick={() => onTopicChanged(topicOfTheDay)}
            sx={{ height: 34 }}
          >
            Use topic of the day
          </Button>
        )}
        {selectedTopic && (
          <IconButton
            sx={{ marginLeft: 'auto' }}
            onClick={() => onTopicChanged(undefined)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Paper>
  );
};

export default TopicSelection;
