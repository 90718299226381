import { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import DashboardPage from './components/pages/DashboardPage';
import InvigoratePage from './components/pages/InvigoratePage';
import FeedbackPage from './components/pages/FeedbackPage';
import LoginPage from './components/pages/LoginPage/LoginPage';
import Header from './components/organisms/Header';
import Footer from './components/organisms/Footer';
import LandingPage from './components/pages/LandingPage';
import CalendarPage from './components/pages/CalendarPage';
import IdeasPage from './components/pages/IdeasPage';
import LeaderboardsPage from './components/pages/LeaderboardsPage';
import PrivacyPage from './components/pages/PrivacyPage';
import { useAppSelector } from './redux/hooks';
import { routes } from './constants/routes';
import { selectIsAuthorized } from './redux/slices/authSlice';

const App = () => {
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const useDarkTheme = useAppSelector(
    (state) => state.preferences.useDarkTheme
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: useDarkTheme ? 'dark' : 'light',
          primary: {
            main: '#4db6ac',
          },
          secondary: {
            main: '#ff8a65',
          },
        },
        typography: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        },
        shape: { borderRadius: 0 },
      }),
    [useDarkTheme]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Router>
          <Box
            sx={{
              display: 'flex',
              minHeight: '100vh',
              flexDirection: 'column',
            }}
          >
            <Header />
            <Box sx={{ flex: 1 }}>
              <Routes>
                <Route
                  path={routes.root}
                  element={isAuthorized ? <DashboardPage /> : <LandingPage />}
                />
                <Route path={routes.login} element={<LoginPage />} />
                <Route path={routes.invigorate} element={<InvigoratePage />} />
                <Route path={routes.calendar} element={<CalendarPage />} />
                <Route
                  path={routes.leaderboards}
                  element={<LeaderboardsPage />}
                />
                <Route path={routes.ideas} element={<IdeasPage />} />
                <Route path={routes.feedback} element={<FeedbackPage />} />
                <Route path={routes.privacy} element={<PrivacyPage />} />
              </Routes>
            </Box>
            <Footer />
          </Box>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
