import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { useLazyGetIdeasForDateQuery } from '../../../redux/services/ideasApi';
import { IdeaModel } from '../../../types/idea';
import AppPage from '../../templates/AppPage';
import Idea from '../../organisms/Idea';

const IdeasPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [date, setDate] = useState(DateTime.now());
  const [fetchIdeas, { data: ideas, isFetching }] =
    useLazyGetIdeasForDateQuery();

  useEffect(() => {
    const dateParam = searchParams.get('date');
    if (dateParam) {
      setDate(DateTime.fromISO(dateParam));
      fetchIdeas(encodeURIComponent(dateParam));
    }
  }, [searchParams]);

  return (
    <AppPage maxWidth="lg">
      <Button
        size={xs ? 'small' : 'medium'}
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        sx={{ marginBottom: { xs: 2, sm: 4 } }}
        fullWidth={xs}
        onClick={() => navigate(routes.calendar)}
      >
        Back to calendar
      </Button>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h4' } }} gutterBottom>
        {date.toFormat('DDDD')}
      </Typography>
      <Divider sx={{ marginBottom: { xs: 2, sm: 4 } }} />
      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <>
            {ideas &&
              ideas.map((idea: IdeaModel, index: number) => (
                <Grid key={idea.id} item xs={12} md={6}>
                  <Card variant="outlined">
                    <CardContent
                      sx={{
                        padding: { xs: 1, sm: 2 },
                        '&:last-child': { paddingBottom: { xs: 1, sm: 2 } },
                      }}
                    >
                      <Idea index={index} idea={idea} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </>
        )}
      </Grid>
    </AppPage>
  );
};

export default IdeasPage;
