const baseAddress = process.env.REACT_APP_API_BASE_ADDRESS;
export const endpoints = {
  googleLogin: `${baseAddress}/.auth/login/google`,
  facebookLogin: `${baseAddress}/.auth/login/facebook`,
  feedback: 'api/feedback',
  ideas: 'api/ideas',
  firstIdea: 'api/ideas/first',
  ideasForDate: (date: string) => `api/ideas?date=${date}`,
  ideaCount: (date: string) => `api/ideas/count?date=${date}`,
  calendarIdeas: (date: string) => `api/ideas/calendar?date=${date}`,
  activeStreaksLeaderboard: 'api/leaderboards/active-streaks',
  longestStreaksLeaderboard: 'api/leaderboards/longest-streaks',
  dailyIdeasLeaderboard: (date: string) =>
    `api/leaderboards/daily-ideas?date=${date}`,
  totalIdeasLeaderboard: 'api/leaderboards/total-ideas',
  userStats: 'api/users/me/stats',
  tags: 'api/tags',
  topicOfTheDay: (date: string) => `api/topics/totd?date=${date}`,
};
