import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Sizes } from '../../../constants/dimensions';

interface ProminentLinkButtonProps {
  route: string;
  text: string;
  size: Sizes;
  fullWidth?: boolean;
}

export default function ProminentLinkButton({
  route,
  text,
  size,
  fullWidth,
}: ProminentLinkButtonProps) {
  return (
    <Button
      variant="contained"
      size={size}
      component={Link}
      to={route}
      sx={{ fontSize: { xs: 18, sm: 20, md: 22 } }}
      fullWidth={fullWidth}
    >
      {text}
    </Button>
  );
}
