import { useAppDispatch } from '../../../redux/hooks';
import { IdentityProvider } from '../../../types/auth';
import { login } from '../../../redux/slices/authSlice';
import { useEffect } from 'react';
import Button from '@mui/material/Button';

const FacebookSignIn = () => {
  const dispatch = useAppDispatch();

  const loadFacebookSdk = () => {
    const scriptId = 'facebook-jssdk';
    const scriptTag = 'script';

    if (document.getElementById(scriptId)) {
      return;
    }

    const scriptNode = document.createElement(scriptTag) as HTMLScriptElement;
    scriptNode.id = scriptId;
    scriptNode.src = 'https://connect.facebook.net/en_US/sdk.js';

    const firstScriptNode = document.getElementsByTagName(scriptTag)[0];
    if (firstScriptNode.parentNode) {
      firstScriptNode.parentNode.insertBefore(scriptNode, firstScriptNode);
    }
  };

  useEffect(() => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        xfbml: true,
        version: 'v14.0',
      });
    };
    loadFacebookSdk();
  }, []);

  const signIn = () => {
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        fetchUserDataAndLogin(response);
      } else {
        FB.login(
          (response) => {
            fetchUserDataAndLogin(response);
          },
          { scope: 'public_profile,email' }
        );
      }
    });
  };

  const fetchUserDataAndLogin = (response: fb.StatusResponse) => {
    FB.api(
      '/me',
      {
        fields: 'name,email,picture',
      },
      (user: any) => {
        dispatch(
          login({
            provider: IdentityProvider.Facebook,
            userId: response.authResponse.userID,
            accessToken: response.authResponse.accessToken,
            name: user.name,
            email: user.email,
            imageUrl: user.picture.data.url,
          })
        );
      }
    );
  };

  return (
    <Button variant="contained" onClick={signIn}>
      Sign in with Facebook
    </Button>
  );
};

export default FacebookSignIn;
