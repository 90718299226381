import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface PagerItemProps {
  label: number;
}

const commonPagerStyles = {
  padding: { xs: 0.5, sm: 1 },
  flex: '1 1 0',
  alignSelf: 'center',
  textAlign: 'center',
};

const CompletedPagerItem = ({ label }: PagerItemProps) => {
  return (
    <Typography
      variant="body1"
      component="span"
      sx={{
        ...commonPagerStyles,
        color: '#000',
        backgroundColor: 'primary.main',
      }}
    >
      {label}
    </Typography>
  );
};

const CurrentPagerItem = ({ label }: PagerItemProps) => {
  return (
    <Typography
      variant="body1"
      component="span"
      sx={{
        ...commonPagerStyles,
        color: 'text.primary',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary.main',
      }}
    >
      {label}
    </Typography>
  );
};

const InactivePagerItem = ({ label }: PagerItemProps) => {
  return (
    <Typography
      variant="body1"
      component="span"
      sx={{
        ...commonPagerStyles,
        color: 'text.secondary',
      }}
    >
      {label}
    </Typography>
  );
};

export interface IdeaPagerProps {
  current: number;
  total: number;
}

export default function IdeaPager({ current, total }: IdeaPagerProps) {
  const getPagerItem = (index: number) => {
    const label = index + 1;
    if (label < current) {
      return <CompletedPagerItem key={label} label={label} />;
    } else if (label === current) {
      return <CurrentPagerItem key={label} label={label} />;
    } else {
      return <InactivePagerItem key={label} label={label} />;
    }
  };

  return (
    <Stack direction="row" spacing={{ xs: 1, sm: 2 }}>
      {Array.from(Array(total), (e, i) => getPagerItem(i))}
    </Stack>
  );
}
