import React, { useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useListTagsQuery } from '../../../redux/services/tagsApi';

interface IdeaTagsProps {
  tags: string[];
  onTagAdded: (tag: string) => void;
  onTagRemoved: (tag: string) => void;
}

const IdeaTags = ({ tags, onTagAdded, onTagRemoved }: IdeaTagsProps) => {
  const [inputting, setInputting] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  const { data: suggestedTags } = useListTagsQuery();

  const open = () => {
    setHasError(false);
    setInputting(true);
  };
  const close = () => setInputting(false);
  const add = () => {
    if (inputValue === '') {
      setError('Tag must have a name');
      setHasError(true);
      return;
    }
    if (tags.some((t) => t.toLowerCase() == inputValue.toLowerCase())) {
      setError('Tag has already been added');
      setHasError(true);
      return;
    }
    onTagAdded(inputValue);
    setInputValue('');
    close();
  };
  const remove = (tag: string) => {
    onTagRemoved(tag);
  };
  const onInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      add();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: { xs: 1, sm: 2 },
      }}
    >
      {tags.map((tag) => (
        <Chip
          key={tag}
          label={tag}
          variant="outlined"
          onDelete={() => remove(tag)}
          sx={{ marginRight: 1, my: 0.5 }}
        />
      ))}
      {inputting ? (
        <Box sx={{ display: 'flex' }}>
          <Tooltip placement="top" arrow title={error} open={hasError}>
            <Autocomplete
              freeSolo
              disablePortal
              options={
                suggestedTags
                  ? suggestedTags
                      .map((suggestedTag) => suggestedTag.name)
                      .filter(
                        (suggestedTag) =>
                          !tags.some((tag) => tag === suggestedTag)
                      )
                  : []
              }
              sx={{ minWidth: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tag"
                  autoFocus
                  onKeyDown={onInputKeyDown}
                />
              )}
              size="small"
              inputValue={inputValue}
              onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue);
              }}
            />
          </Tooltip>
          <IconButton color="success" onClick={add}>
            <CheckCircleIcon />
          </IconButton>
          <IconButton color="warning" onClick={close}>
            <CancelIcon />
          </IconButton>
        </Box>
      ) : (
        <>
          {tags && tags.length > 0 ? (
            <IconButton color="secondary" onClick={open}>
              <AddBoxIcon />
            </IconButton>
          ) : (
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={open}
            >
              Add tag
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default IdeaTags;
