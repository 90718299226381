import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/auth';
import { RootState } from '../store';

interface AuthState {
  user?: User;
  accessToken?: string;
  forceLogout: boolean;
}

const initialState: AuthState = {
  forceLogout: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.forceLogout = false;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    logout: () => {
      return initialState;
    },
    forceLogout: () => {
      return {
        ...initialState,
        forceLogout: true,
      };
    },
  },
});

export const { login, setAccessToken, logout, forceLogout } = authSlice.actions;

export const selectIsAuthorized = (state: RootState): boolean =>
  !!state.auth.accessToken;

export default authSlice.reducer;
