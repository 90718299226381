import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface IdeaCounterProps {
  value: number;
  threshold: number;
}

export default function IdeaCounter({ value, threshold }: IdeaCounterProps) {
  return (
    <Box>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h4' } }} component="span">
        {value}
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'h6', sm: 'h4' },
          mx: { xs: 0.5, sm: 1 },
        }}
        component="span"
      >
        /
      </Typography>
      <Typography sx={{ typography: { xs: 'h6', sm: 'h4' } }} component="span">
        {threshold}
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'subittle2', sm: 'subittle1' },
          marginLeft: { xs: 0.5, sm: 1 },
        }}
        component="span"
      >
        ideas today
      </Typography>
    </Box>
  );
}
