import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { routes } from '../../../constants/routes';
import ProminentLinkButton from '../../atoms/ProminentLinkButton';
import AppPage from '../../templates/AppPage';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const LandingPage = () => {
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <AppPage maxWidth="xl">
      <Grid container spacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={12} md={6}>
          <Box
            component="img"
            sx={{
              height: 'auto',
              width: '100%',
            }}
            alt="Invigoriam hero image"
            src="/images/landing-page.jpg"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid container spacing={{ xs: 2, sm: 4 }}>
            <Grid item xs={12} sm={5}>
              <Typography sx={{ typography: { xs: 'h4', sm: 'h3' } }}>
                Train your mind to come up with ideas easily
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Box>
                <Typography
                  sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1' } }}
                  gutterBottom
                >
                  Training your mind is much like training any other body part -
                  you must exercise it and do it consistently.
                </Typography>
                <Typography
                  sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1' } }}
                  gutterBottom
                >
                  Invigoriam aims to provide a platform for exercising your mind
                  - more specifically - the idea muscle by helping you write at
                  least 10 ideas every day.
                </Typography>
                <Typography
                  sx={{ typography: { xs: 'subtitle2', sm: 'subtitle1' } }}
                >
                  By doing this consistently you will train your mind to be able
                  to come up with ideas easily. Be it to come up with new ways
                  to make money, improve your relationships or gain new
                  experiences.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ProminentLinkButton
                route={routes.login}
                text="Start your journey"
                size={xs ? 'small' : 'large'}
                fullWidth={xs}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppPage>
  );
};

export default LandingPage;
