import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { endpoints } from '../../constants/endpoints';
import { FeedbackRequest, FeedbackResponse } from '../../types/feedback';
import { RootState } from '../store';

const baseAddress = process.env.REACT_APP_API_BASE_ADDRESS;

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseAddress,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.accessToken;
      if (token) {
        headers.set('X-ZUMO-AUTH', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Tags'],
  endpoints: (builder) => ({
    submitFeedback: builder.mutation<FeedbackResponse, FeedbackRequest>({
      query: (request) => ({
        url: endpoints.feedback,
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const { useSubmitFeedbackMutation } = baseApi;
