import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toggleDarkTheme } from '../../../redux/slices/preferenceSlice';
import HeaderMenuButton, {
  HeaderMenuButtonProps,
} from '../../atoms/HeaderMenuButton';
import HeaderLogo from '../../molecules/HeaderLogo';
import HeaderLogoImage from '../../atoms/HeaderLogoImage';
import NavigationDrawer from '../../molecules/NavigationDrawer';
import UserMenu from '../../molecules/UserMenu';
import { routes } from '../../../constants/routes';
import { getIconSizes, getFooterSizes } from '../../../constants/dimensions';
import { selectIsAuthorized } from '../../../redux/slices/authSlice';

const menuItems: HeaderMenuButtonProps[] = [
  { text: 'Home', route: routes.root },
  { text: 'Invigorate', route: routes.invigorate },
  { text: 'Calendar', route: routes.calendar },
  { text: 'Leaderboards', route: routes.leaderboards },
];

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuthorized = useAppSelector(selectIsAuthorized);
  const useDarkTheme = useAppSelector(
    (state) => state.preferences.useDarkTheme
  );
  const forceLogout = useAppSelector((state) => state.auth.forceLogout);

  useEffect(() => {
    if (forceLogout) {
      navigate(routes.login);
    }
  }, [forceLogout]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  const openUserMenu = (event: any) => setUserMenuAnchor(event.currentTarget);
  const closeUserMenu = () => setUserMenuAnchor(null);

  const toggleColorTheme = () => dispatch(toggleDarkTheme());
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <AppBar color="transparent" position="static" elevation={0}>
      <Toolbar
        sx={{
          alignItems: 'center',
          py: { xs: 0.5, sm: 1 },
          '@media all': {
            minHeight: getFooterSizes(),
          },
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            marginRight: { md: 2, lg: 4 },
          }}
        >
          <HeaderLogo />
        </Box>
        {isAuthorized && (
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
            >
              <MenuIcon sx={{ fontSize: getIconSizes() }} />
            </IconButton>
            <NavigationDrawer
              menuItems={menuItems}
              open={drawerOpen}
              close={toggleDrawer}
            />
          </Box>
        )}
        <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'none' } }}>
          <HeaderLogo />
        </Box>
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <HeaderLogoImage clickable size="small" />
        </Box>
        {isAuthorized && (
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map((menuItem) => (
              <HeaderMenuButton
                key={menuItem.text}
                text={menuItem.text}
                route={menuItem.route}
              />
            ))}
          </Box>
        )}
        <Box flexGrow={1} />
        <IconButton
          color="inherit"
          aria-label="Submit feedback"
          onClick={() => navigate(routes.feedback)}
        >
          <FeedbackIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="Toggle light/dark theme"
          onClick={toggleColorTheme}
        >
          {useDarkTheme ? (
            <Brightness7Icon sx={{ fontSize: getIconSizes() }} />
          ) : (
            <Brightness4Icon sx={{ fontSize: getIconSizes() }} />
          )}
        </IconButton>
        <IconButton
          color="inherit"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={openUserMenu}
        >
          <AccountCircleIcon sx={{ fontSize: getIconSizes() }} />
        </IconButton>
        <UserMenu anchor={userMenuAnchor} close={closeUserMenu} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
